.wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.tabs li {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none;
    background-color: #f9f9f9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tabs li.active {
    background-color: #e9e9e9;
    border-bottom: 1px solid #ccc;
}

.dataTable {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px; /* Adjust the margin as needed */
}

.dataTable th, .dataTable td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px; /* Adjust the padding as needed */
}

.dataTable .text-center {
    text-align: center;
}

.totalRow td {
    font-weight: bold;
}

